<template>
    <div class="nav-left-panel">
        <ts-search :param="{}"></ts-search>

        <div class="nav-left-panel__list">
            <div class="nav-left-panel__item">
                <p class="nav-left-panel__title">上市药品</p>
                <p class="nav-left-panel__desc" v-for="item in drugList" :key="item.id" @click="$help.openUrl(item)">
                    {{item.viewName}}
                    <img v-if="item.hot" class="hot" src="@/assets/img/hot.png" alt="hot">
                </p>
            </div>

            <div class="nav-left-panel__item">
                <p class="nav-left-panel__title">合理用药</p>
                <p class="nav-left-panel__desc" v-for="item in reasonableList" :key="item.id" @click="$help.openUrl(item)">
                    {{item.viewName}}
                    <img v-if="item.hot" class="hot" src="@/assets/img/hot.png" alt="hot">
                </p>
            </div>

            <div class="nav-left-panel__item">
                <p class="nav-left-panel__title">市场行情</p>
                <p class="nav-left-panel__desc" v-for="item in marketList" :key="item.id" @click="$help.openUrl(item)">
                    {{item.viewName}}
                    <img v-if="item.hot" class="hot" src="@/assets/img/hot.png" alt="hot">
                </p>

            </div>

            <div class="nav-left-panel__item">
                <p class="nav-left-panel__title">药品检查</p>
                <p class="nav-left-panel__desc" v-for="item in checkList" :key="item.id" @click="$help.openUrl(item)">
                    {{item.viewName}}
                    <img v-if="item.hot" class="hot" src="@/assets/img/hot.png" alt="hot">
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                visitId: new Date().getTime(),
                pageId: 'navService',
                pageName: '导航-服务商所需',

                searchTxt: '',

                drugList: [],
                reasonableList: [],
                marketList: [],
                checkList: [],
            }
        },
        created() {
            this.$help.getUrlIndex(10, 20, undefined, this.visitId)
                .then(res => {
                    this.drugList = res.data.records
                })
            this.$help.getUrlIndex(11, 20, undefined, this.visitId)
                .then(res => {
                    this.reasonableList = res.data.records
                })
            this.$help.getUrlIndex(12, 20, undefined, this.visitId)
                .then(res => {
                    this.marketList = res.data.records
                })
            this.$help.getUrlIndex(13, 20, undefined, this.visitId)
                .then(res => {
                    this.checkList = res.data.records
                })
            this.$help.socket.send(this)
        },
        methods: {
            querySearch(queryString, cb) {
                this.$api.get('setting/aimBaseDrugs/queryAimBaseDrugsListByKeyword', {keyword: queryString}).then(res => {
                    cb(res.data);
                });
            },
            search() {
                if(this.searchTxt) {
                    this.$router.push({
                        path: `/drug/${this.$help.encryption(this.searchTxt)}`,
                    })
                }
            },
        }
    }
</script>